class MobileMenu {
    constructor() {
        this.overlay = document.querySelector('.overlay');
        this.closeButton = document.querySelector('button.js-close');
        this.openButton = document.querySelector('button.js-open');

        this.bindEvents();
    }

    bindEvents() {
        //this.overlay.addEventListener('click', this.closeMenu.bind(this));
        this.openButton.addEventListener('click', this.showMenu.bind(this));
        this.closeButton.addEventListener('click', this.closeMenu.bind(this));
    }

    showMenu(event) {
        event.preventDefault();
        this.overlay.classList.remove('hidden');
    }

    closeMenu(event) {
        event.preventDefault();
        if ((event.target === this.overlay) || (event.currentTarget === this.closeButton)) {
            this.overlay.classList.add('hidden');
        }
    }
}
export default MobileMenu;