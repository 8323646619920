import MobileMenu from "./MobileMenu";
new MobileMenu();

/*
const overlay = document.querySelector('.overlay');
overlay.addEventListener('click', function (event) {
    if (event.target.classList.contains('js-close')) {
        overlay.classList.add('hidden');
    }
});

const closeButton = document.querySelector('button.js-close');
closeButton.addEventListener('click', function (event) {
    event.preventDefault();
    overlay.classList.add('hidden');
});

const openMenu = document.querySelector('button.js-open');
openMenu.addEventListener('click', function (event) {
    event.preventDefault();
    overlay.classList.remove('hidden');
});
*/